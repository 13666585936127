import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { StyledHeader as Header } from '../components/header/Header';
import { StyledFooter as Footer } from '../components/footer/Footer';
import { StyledLogo as Logo } from '../components/logo/Logo';
import { StyledMenu as Menu } from '../components/menu/Menu';

import { respondTo } from '../helpers/breakpoints/breakpoints';
import { colors } from '../helpers/colors/colors';

// Context
import { ViewportContext } from '../context/ViewportContext';

const GlobalStyle = createGlobalStyle`
html, body
  {
      width: 100%;
      margin: 0px;
      padding: 0px;
      overflow-x: hidden; 
  }

  body {   
    width: 100%;
    margin: 0 auto;
    padding-top: 75px;
    font-family: "inter";
    font-size: 15px;
    line-height: 20px;
    ${respondTo('sm', {
      ['font-size']: `17px`,
      ['line-height']: `24px`,
    })}
    color: ${colors.black};
    background: ${colors.white};
    font-smoothing: antialiased;
  }
  a, a:active, a:visited {
    color: ${colors.black};
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
  @media (prefers-color-scheme: dark) {
    body, a, a:active, a:visited {
      color: ${colors.white};
      background: ${colors.black};
    }
  }
  @media (prefers-color-scheme: light) {
    body, a, a:active, a:visited {
      color: ${colors.black};
      background: ${colors.white};
    }
  }
 
`;

export interface MainLayoutProps extends React.HTMLAttributes<HTMLElement> {
  isLoading: boolean;
  toggleTheme: (e: React.MouseEvent) => void;
  hasLogo: boolean;
  changeNavigation: (navigationTarget: string) => void;
  activeNavigation: string;
  changeModal: (modalName: string) => void;
  activeModal: string;
}

export const LayoutMain = styled.main.attrs({
  className: '',
})<MainLayoutProps>`
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutMain: React.FC<MainLayoutProps> = (
  props: MainLayoutProps
): JSX.Element => {
  const {
    children,
    toggleTheme,
    isLoading,
    hasLogo,
    changeNavigation,
    activeNavigation,
    changeModal,
    activeModal,
  } = props;
  const viewportContext = useContext(ViewportContext);
  const { width } = viewportContext;

  return (
    <>
      <div
        style={{
          position: 'relative',
        }}
      >
        <Header>
          <div
            className="logo-container"
            style={
              hasLogo
                ? {
                    transform: `translateX(0)`,
                    transition: 'all ease-in-out .5s',
                  }
                : {
                    transform: `translateX(-100%)`,
                    transition: 'all ease-in-out .5s',
                  }
            }
          >
            <Logo
              className="header-logo"
              onClick={() => changeNavigation('home')}
              height={48}
              to={'/'}
            ></Logo>
          </div>
          <div>
            <Menu
              items={[
                { label: 'Leistungen', name: 'leistungen' },
                { label: 'Team', name: 'team' },
              ]}
              handleNavigationChange={changeNavigation}
              activeNavigation={activeNavigation}
            />
          </div>
        </Header>
        <LayoutMain
          isLoading={isLoading}
          hasLogo={hasLogo}
          activeModal={activeModal}
        >
          <GlobalStyle activeModal={activeModal} />

          {children}
        </LayoutMain>
        <Footer toggleTheme={toggleTheme} changeModal={changeModal}></Footer>
      </div>
    </>
  );
};

export default StyledLayoutMain;
