import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { StyledLayoutMain as LayoutMain } from '../layouts/Main';
import { SiteContext } from '../context/SiteContext';
import { ViewportContext } from '../context/ViewportContext';
import { breakpoints } from '../helpers/breakpoints/breakpoints';
import { StyledMobileView as MobileView } from '../views/Mobile';
import { StyledDesktopView as DesktopView } from '../views/Desktop';

export default function Home() {
  const siteContext = useContext(SiteContext);
  const viewportContext = useContext(ViewportContext);
  const [hasLogo, setHasLogo] = useState(false)


  useEffect(() => {
    if (siteContext.activeNavigation === "home") {
      setHasLogo(false)
    } else {
      setHasLogo(true)
    }
  }, [siteContext.activeNavigation])

  return (
    <>
      <Helmet>
        <title>
          Startseite - Klickste UG
        </title>
      </Helmet>
      <LayoutMain
        theme={siteContext.theme}
        toggleTheme={siteContext.toggleTheme}
        changeNavigation={siteContext.changeNavigation}
        changeModal={siteContext.changeModal}
        hasLogo={hasLogo}
        activeNavigation={siteContext.activeNavigation}
        activeModal={siteContext.activeModal}
      >
        {viewportContext.width < breakpoints.lg ? (
          <MobileView
            theme={siteContext.theme}
            activeNavigation={siteContext.activeNavigation}
            changeNavigation={siteContext.changeNavigation}
            currentBreakpoint={viewportContext.currentBreakpoint}
          />
        ) : (
          <DesktopView
            theme={siteContext.theme}
            activeNavigation={siteContext.activeNavigation}
            changeNavigation={siteContext.changeNavigation}
            currentBreakpoint={viewportContext.currentBreakpoint}
          />
        )}
      </LayoutMain >
    </>
  );
}
