import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../../helpers/colors/colors';
import { space } from '../../helpers/space/';
import { Headline as BaseHeadline } from '../headline/Headline';
import { Paragraph as BaseParagraph } from '../paragraph/Paragraph';
export interface AvatarProps extends React.HTMLAttributes<HTMLElement> {
  image?: string;
  name?: string;
  title?: string;
  width?: number;
  height?: number;
  sizeMode?: 'contain' | 'cover';
}

export const Avatar = styled.div.attrs({
  className: 'avatar',
})<AvatarProps>`
width: ${(props) => props.width}px;
height: calc(${(props) => props.height}px + ${space(4)});
position: relative;
margin: ${space(0.5)} ${space(0.5)} 0 0;

background: ${colors.white};
@media (prefers-color-scheme: dark) {
  background: ${colors.black};
}
@media (prefers-color-scheme: light) {
  background: ${colors.white};
}
> p {
  margin: 0;
  margin-top: ${space(0.25)};
}

&:hover {
  .avatar-content {
    opacity: 1;
    transform: translateY(0%);
    transition: all ease-in-out 0.5s;
  }
}

.avatar-image {
  width: 100%;
  height: ${(props) => props.height}px;
  z-index: 1;
  background-size: ${(props) => props.sizeMode};
  filter: grayscale(100%);
  border-radius: 4px;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  &:hover {
    filter: grayscale(0);
  }
}

.avatar-content {
  padding: ${space(0.5)};
  width: 100%;
  z-index: 2;
  opacity: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translateY(100%);
  transition: all ease-in-out 0.5s;
  background: ${colors.white};
  @media (prefers-color-scheme: dark) {
    background: ${colors.black};
  }
  @media (prefers-color-scheme: light) {
    background: ${colors.white};
  }
}
`;

const Headline = styled(BaseHeadline)`
  line-height: 1;
  margin-bottom: ${space(0.5)};
`;

const Paragraph = styled(BaseParagraph)`
  line-height: 1.2;
`;

export const StyledAvatar: React.FC<AvatarProps> = (
  props: AvatarProps
): JSX.Element => {
  const {
    children,
    image,
    name,
    title,
    width = 160,
    height = 160,
    sizeMode = 'cover',
  } = props;
  const avatarImg = require('../../../static/img/' + image);

  return (
    <Avatar width={width} height={height} sizeMode={sizeMode}>
      <div
        className="avatar-image"
        style={{ backgroundImage: `url(${avatarImg.default})` }}
      ></div>
      <div className="avatar-content">
        <Headline
          as="h4"
          fontWeight={600}
          isPrimary={true}
          hasSpacing={false}
          isNarrow={true}
        >
          {name}
        </Headline>
        <Paragraph isNarrow={true}>{title}</Paragraph>
      </div>
    </Avatar>
  );
};

export default StyledAvatar;
