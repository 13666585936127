import * as React from 'react';
import styled from 'styled-components';
export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  innerRef?: HTMLElement | null;
}

export const Section = styled.section.attrs({
  className: '',
})<SectionProps>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: 90vh;
`;

export const StyledSection: React.FC<SectionProps> = (
  props: SectionProps
): JSX.Element => {
  const { children, innerRef } = props;

  return (
    <Section ref={innerRef} {...props}>
      {children}
    </Section>
  );
};

export default StyledSection;
