import * as React from 'react';
import styled from 'styled-components';
export interface BackProps extends React.HTMLAttributes<HTMLElement> {
  position: 'right' | 'left';
}

export const Back = styled.div.attrs({
  className: 'back',
})<BackProps>`
  flex: 1 1 100%;
  top: 0;
  display: flex;
  justify-content: ${({ position }) =>
    position === 'left' ? 'flex-end' : 'flex-start'};
  order: ${({ position }) => (position === 'left' ? 1 : 2)};
  z-index: 1;
  filter: ${({ visibility }) => `opacity(${1 - visibility})`};
`;

export const StyledBack: React.FC<BackProps> = (
  props: BackProps
): JSX.Element => {
  const { children, position } = props;

  return (
    <Back className={position} {...props}>
      {children}
    </Back>
  );
};

export default StyledBack;
