import React, { useRef, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

// Types
import { Content, SlideGroup as SlideGroupType } from '../../types';

// Helpers
import { calculateVisibility } from '../../helpers/utilities';

import { SiteContext } from '../../context/SiteContext';

export interface SliderProps extends React.HTMLAttributes<HTMLElement> {
  slides: Array<Content>;
  active: boolean;
  headline?: string;
  activeNavigation: string;
  changeNavigation: (navigationTarget: string) => void;
}

export const Slider = styled.div.attrs({
  className: '',
})<SliderProps>`
  box-sizing: border-box;
  display: flex;
  height: 80vh;
  max-height: 800px;
  position: relative;
  width: 100%;
  .viewbox {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scroller {
    display: flex;
    flex-direction: row;
  }
`;

export const StyledSlider: React.FC<SliderProps> = (
  props: SliderProps
): JSX.Element => {
  const {
    children,
    slides,
    active = false,
    activeNavigation,
    changeNavigation,
  } = props;
  const el = useRef(null);

  const siteContext = useContext(SiteContext);

  const [slideCount, setSlideCount] = useState(0);
  const [slideRefs, setSlideRefs] = useState([]);
  const [slideGroupRefs, setSlideGroupRefs] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const container = el.current;

    if (container) {
      window.addEventListener('wheel', scroll);

      container.addEventListener('scroll', function (e) {
        runSlideAnimations();
        runSlideGroupAnimations();
      });

      window.addEventListener('keydown', keyboardListener);

      document.querySelector('html').style.overflowY = 'hidden';
      document.querySelector('body').style.overflowY = 'hidden';
      return function cleanup() {
        window.removeEventListener('wheel', scroll);
        window.removeEventListener('keydown', keyboardListener);
        container.removeEventListener('scroll', runSlideAnimations);
        container.removeEventListener('scroll', runSlideGroupAnimations);
        document.querySelector('html').style.overflowY = 'inherit';
        document.querySelector('body').style.overflowY = 'inherit';
      };
    }
  }, [el, slideRefs]);

  const updateSlideRefs = (ref) => {
    setSlideRefs((oldRefs) => [...oldRefs, ref]);
  };

  const updateSlideGroupRefs = (groupRef) => {
    setSlideGroupRefs((oldRefs) => [...oldRefs, groupRef]);
  };

  const runSlideGroupAnimations = () => {
    if (slideGroupRefs.length === 0) {
      return;
    }
    slideGroupRefs.forEach((ref, index) => {
      const elementVisibility = calculateVisibility(ref);
      const headline = ref.querySelector('.floating-headline');

      if (elementVisibility > 0) {
        if (headline) {
          headline.style.left = `${150 * elementVisibility}vw`;
          headline.childNodes[0].style.paddingLeft = `${
            150 * elementVisibility
          }px`;
        }
      }
    });
  };

  const runSlideAnimations = () => {
    if (slideRefs.length === 0) {
      return;
    }

    slideRefs.forEach((ref, index) => {
      const elementVisibility = calculateVisibility(ref);
      if (elementVisibility > 0) {
        const frontLayer = ref.querySelector('.front');
        if (frontLayer) {
          if (Array.from(frontLayer.classList).includes('left')) {
            frontLayer.style.transform = `translateX(calc(${
              elementVisibility * 5
            } * 2rem))`;
          } else {
            frontLayer.style.transform = `translateX(-calc(${
              elementVisibility * 5
            } * 2rem))`;
          }
          frontLayer.style.filter = `opacity(${1 - elementVisibility} )`;
        }
        const backLayer = ref.querySelector('.back');
        if (backLayer) {
          if (Array.from(backLayer.classList).includes('left')) {
            backLayer.style.transform = `translateX(calc(${
              elementVisibility * 10
            } * 1rem)`;
          } else {
            backLayer.style.transform = `translateX(-calc(${
              elementVisibility * 10
            } * 1rem))`;
          }
        }
        const content = ref.querySelector('.content');
        if (content) {
          content.style.marginRight = `calc(${elementVisibility * 10} * 2rem)`;
        }
      }
    });
  };

  const keyboardListener = function (e: KeyboardEvent) {
    const keyCode = e.code;
    if (keyCode === 'ArrowDown' || keyCode === 'ArrowRight') {
      scroll(e, 20);
    }
    if (keyCode === 'ArrowUp' || keyCode === 'ArrowLeft') {
      scroll(e, -20);
    }
  };

  const scroll = function (e, offset?: number) {
    let delta = e.deltaY;
    if (offset) {
      delta = offset;
    }
    const container = el.current;
    const pos = container?.scrollLeft;
    const adjustment = pos + delta;
    container.scrollLeft = adjustment;
  };

  useEffect(() => {
    const container = el.current;

    if (container && slideGroupRefs) {
      const currentRef = slideGroupRefs.find(
        (ref) => ref.id === activeNavigation
      );

      if (currentRef) {
        container.scroll({ left: currentRef.offsetLeft, behavior: 'smooth' });
      }
    }
  }, [activeNavigation]);

  const handleSlideGroupMount = (slideGroup: SlideGroupType) => {
    setSlideCount((oldSlideCount) => oldSlideCount + slideGroup.slideCount);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        activeSlide: activeSlide,
        handleMount: handleSlideGroupMount,
        slideCount: slideCount,
        changeNavigation: changeNavigation,
        activeNavigation: activeNavigation,
        updateSlideRefs: updateSlideRefs,
        updateSlideGroupRefs: updateSlideGroupRefs,
      });
    }
    return child;
  });

  return (
    <Slider slides={slides} active={active}>
      <div className="viewbox" ref={el}>
        <div className="scroller">{childrenWithProps}</div>
      </div>
    </Slider>
  );
};

export default StyledSlider;
