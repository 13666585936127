import React, { useState } from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';
import { respondTo } from '../../helpers/breakpoints/breakpoints';
import { StyledButton as Button } from '../button/Button';
import { MenuItem } from '../../types';
import { StyledClose as Close } from '../close/Close';
import { PARAGRAPH_SIZES as SIZES } from '../../helpers/typography/typography';

export interface MenuProps extends React.HTMLAttributes<HTMLElement> {
  innerRef?: HTMLElement | null;
  items?: MenuItem[];
  handleNavigationChange: (navigationTarget: string) => void;
  activeNavigation: string;
}

export const Menu = styled.div.attrs({
  className: '',
})<MenuProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
  ${respondTo('sm', {
    ['font-size']: `${SIZES['default']}px`,
  })}
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    li {
      margin: ${space(0.5)};
      font-weight: bold;
      a {
        text-decoration: none;
        &:hover {
          cursor: pointer;
        }
      }
      &.is-active {
        a {
          color: ${colors.green};
        }
      }
    }
    max-width: 0px;
    transition: max-width ease-in-out 0.25s;
    &.is-expanded {
      max-width: 500px;
    }
  }
  .menu-container {
    display: flex;
    flex-direction: row;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
  }
  .progress-bar-container {
    display: flex;
    width: 100%;
    .progress-bar {
      background-color: red;
      height: 4px;
    }
  }
  @media (prefers-color-scheme: dark) {
    .button-container {
      background-color: ${colors.black};
    }
  }
  @media (prefers-color-scheme: light) {
    .button-container {
      background-color: ${colors.white};
    }
  }
`;

export const StyledMenu: React.FC<MenuProps> = (
  props: MenuProps
): JSX.Element => {
  const {
    children,
    innerRef,
    items,
    handleNavigationChange,
    activeNavigation,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Menu items={items} ref={innerRef} {...props}>
      <div className="menu-container">
        <ul className={isExpanded ? 'is-expanded' : ''}>
          {items.map((item, index) => (
            <li
              className={item.name === activeNavigation ? 'is-active' : ''}
              key={index}
            >
              <a onClick={() => handleNavigationChange(item.name)}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="button-container">
          <Button none={'tiny'} onClick={() => setIsExpanded(!isExpanded)}>
            <Close isOpen={isExpanded} />
          </Button>
        </div>
      </div>
    </Menu>
  );
};

export default StyledMenu;
