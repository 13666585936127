import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';
import { respondTo } from '../../helpers/breakpoints/breakpoints';

export interface FrontProps extends React.HTMLAttributes<HTMLElement> {
  position: 'right' | 'left';
}

export const Front = styled.div.attrs({
  className: `front`,
})<FrontProps>`
  flex: 1 1 100%;
  top: 0;
  display: flex;
  justify-content: ${({ position }) =>
    position === 'left' ? 'flex-end' : 'flex-start'};
  order: ${({ position }) => (position === 'left' ? 1 : 2)};
  z-index: 2;
`;

export const StyledFront: React.FC<FrontProps> = (
  props: FrontProps
): JSX.Element => {
  const { children, position } = props;

  return (
    <Front
      className={position}
      {...props}
    >
      {children}
    </Front>
  );
};

export default StyledFront;
