import * as React from 'react';
import styled from 'styled-components';

import { StyledAvatar as Avatar } from '../components/avatar/Avatar';
import { StyledCard as Card } from '../components/card/Card';
import { colors } from '../helpers/colors/colors';
import { BreakpointKeys } from '../helpers/breakpoints/breakpoints';
import { StyledSection as Section } from '../components/section/Section';
import { StyledButton as Button } from '../components/button/Button';
import { StyledSymbol as Symbol } from '../components/symbol/Symbol';
import {
  StyledMiniGrid as Grid,
  StyledMiniGridRow as Row,
  StyledMiniGridColumn as Column,
} from '../components/mini-grid/MiniGrid';
import { StyledParagraph as Paragraph } from '../components/paragraph/Paragraph';
import { StyledHeadline as Headline } from '../components/headline/Headline';
import { StyledBackdrop as Backdrop } from '../components/backdrop/Backdrop';
import { StyledGroup as Group } from '../components/group/Group';
import { StyledPill as Pill } from '../components/pill/Pill';
import { StyledSlider as Slider } from '../components/slider/Slider';
import { StyledSlide as Slide } from '../components/slider/Slide';
import { StyledSlideGroup as SlideGroup } from '../components/slider/SlideGroup';
import { StyledLogo as Logo } from '../components/logo/Logo';
import { Theme } from '../types';

// Data
import content from '../data/leistungen.json';
import team from '../data/team.json';
import projects from '../data/projects.json';

const LOGO_SIZE = {
  none: 48,
  sm: 64,
  md: 72,
  lg: 96,
  xl: 128,
  '2xl': 164,
};

export interface DesktopViewProps extends React.HTMLAttributes<HTMLElement> {
  innerRef?: HTMLElement | null;
  theme: Theme;
  activeNavigation: string;
  changeNavigation: (navigationTarget: string) => void;
  currentBreakpoint: BreakpointKeys;
}

export const DesktopView = styled.div.attrs({
  className: '',
})<DesktopViewProps>`
  display: flex;
`;

export const StyledDesktopView: React.FC<DesktopViewProps> = (
  props: DesktopViewProps
): JSX.Element => {
  const {
    innerRef,
    theme,
    activeNavigation,
    changeNavigation,
    currentBreakpoint,
  } = props;

  return (
    <DesktopView
      ref={innerRef}
      theme={theme}
      currentBreakpoint={currentBreakpoint}
      {...props}
    >
      <Section>
        <Slider
          active={false}
          slides={content}
          activeNavigation={activeNavigation}
          changeNavigation={changeNavigation}
        >
          <SlideGroup name="home" id="home">
            <Slide
              index={0}
              front={
                <div className="content">
                  <Grid>
                    <Row>
                      <Column justify={'end'} align={'start'}>
                        <Logo
                          rotation={0}
                          height={LOGO_SIZE[currentBreakpoint]}
                          hasWordmark={true}
                          title={'Home'}
                          to={'/'}
                        />
                      </Column>
                      <Column justify={'end'} align={'center'}>
                        <Button
                          onClick={() => changeNavigation('leistungen')}
                          hasBorder={false}
                          lg={'large'}
                          xl={'extra-large'}
                        >
                          <Symbol
                            symbolStyle="fal"
                            symbolName={'arrow-right'}
                            color={
                              theme === 'light' ? colors.black : colors.white
                            }
                            size="sm"
                          />
                          Leistungen
                        </Button>
                      </Column>
                    </Row>
                    <Row>
                      <Column align="end">
                        <Paragraph
                          align={'right'}
                          fontWeight={500}
                          insetLeft={'10%'}
                          lg={'default'}
                          xl={'large'}
                        >
                          Wir gestalten, entwickeln & vermarkten deinen
                          Webauftritt.
                          <br />
                          Professionell. Zielgerichtet. Individuell.
                        </Paragraph>
                        <Paragraph align={'right'}>
                          <Button
                            as={'a'}
                            to={'mailto:fragste@klickste.berlin'}
                            hasBorder={true}
                            hasEmphasis={false}
                            isPrimary={true}
                            onClick={() => null}
                            lg={'default'}
                            xl={'medium'}
                          >
                            <span>
                              {'Kontaktiere uns unter'}{' '}
                              <strong>fragste@klickste.berlin</strong>
                            </span>
                          </Button>
                        </Paragraph>
                      </Column>
                      <Column></Column>
                    </Row>
                  </Grid>
                </div>
              }
            />
          </SlideGroup>
          <SlideGroup
            headline={'Leistungen'}
            name={'leistungen'}
            id={'leistungen'}
          >
            {content.map((slide, index) => {
              const back = (
                <Backdrop image={slide.image} height={'100%'} width={'33vw'} />
              );
              const front = (
                <div className="restricted-content">
                  <Headline
                    as="h2"
                    fontWeight={600}
                    lg={'extra-small'}
                    xl={'small'}
                    align={index + 1 > content.length / 2 ? 'left' : 'right'}
                    hasSpacing={true}
                  >
                    {slide.headline}
                  </Headline>
                  <Paragraph
                    fontWeight={400}
                    align={index + 1 > content.length / 2 ? 'left' : 'right'}
                    lg={'default'}
                    xl={'default'}
                  >
                    {slide.content}
                  </Paragraph>

                  <Group
                    align={index + 1 > content.length / 2 ? 'left' : 'right'}
                  >
                    {slide.keywords.map((word, index) => (
                      <Pill key={index}>{word}</Pill>
                    ))}
                  </Group>
                </div>
              );

              return (
                <Slide
                  key={index}
                  front={front}
                  back={back}
                  index={index + 1}
                ></Slide>
              );
            })}
          </SlideGroup>

          <SlideGroup
            headline={'Team'}
            name="team"
            id={'team'}
            previous={{
              title: 'Leistungen',
              onClick: () => changeNavigation('leistungen'),
            }}
          >
            <Slide index={content.length + 1}>
              <div className="content">
                <Group align={'right'}>
                  {team.map((member, index) => (
                    <Avatar
                      key={index}
                      image={member.image}
                      name={member.name}
                      title={member.position}
                      height={220}
                      width={166}
                    />
                  ))}
                </Group>
              </div>
            </Slide>
          </SlideGroup>

          {/* <SlideGroup
            headline={'Referenzen'}
            name="referenzen"
            id={'referenzen'}
            previous={{
              title: 'Leistungen',
              onClick: () => changeNavigation('leistungen'),
            }}
          >
            <Slide index={content.length + 2}>
              <div className="content">
                <Group align={'right'}>
                  {projects.map((member, index) => (
                    <Card
                      width={200}
                      height={200}
                      key={index}
                      image={member.image}
                      name={member.name}
                      title={member.position}
                      sizeMode={'contain'}
                    />
                  ))}
                </Group>
              </div>
            </Slide>
          </SlideGroup> */}
        </Slider>
      </Section>
    </DesktopView>
  );
};

export default StyledDesktopView;
