import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { respondTo } from '../../helpers/breakpoints/breakpoints';

const alignAndJustifyContent = function (position: string) {
  let alignmentOrJustification = '';

  switch (position) {
    case 'end': {
      alignmentOrJustification = 'flex-end';
      break;
    }
    case 'center': {
      alignmentOrJustification = 'center';
      break;
    }
    default:
      alignmentOrJustification = 'flex-start';
  }

  return alignmentOrJustification;
};

const determineWidth = function (ratio: string): string {
  let returnValue = '';
  switch (ratio) {
    case '1/2':
      returnValue = '50%';
      break;
    case '1/3':
      returnValue = '33%';
      break;
    case '2/3':
      returnValue = '66%';
      break;
  }
  return returnValue;
};

export interface MiniGridProps extends React.HTMLAttributes<HTMLElement> {}

// Grid
export const MiniGrid = styled.div.attrs({
  className: '',
})<MiniGridProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StyledMiniGrid: React.FC<MiniGridProps> = (
  props: MiniGridProps
): JSX.Element => {
  const { children } = props;

  return <MiniGrid>{children}</MiniGrid>;
};

export interface MiniGridRowProps extends React.HTMLAttributes<HTMLElement> {}

// Row
export const MiniGridRow = styled.div.attrs({
  className: '',
})<MiniGridRowProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${respondTo('md', {
    ['flex-direction']: 'row',
  })}
`;

export const StyledMiniGridRow: React.FC<MiniGridRowProps> = (
  props: MiniGridRowProps
): JSX.Element => {
  const { children } = props;

  return <MiniGridRow {...props}>{children}</MiniGridRow>;
};

// Column
export interface MiniGridColumnProps extends React.HTMLAttributes<HTMLElement> {
  justify?: string;
  align?: string;
  ratio?: '1/2' | '1/3' | '2/3';
}

export const MiniGridColumn = styled.div.attrs({
  className: '',
})<MiniGridColumnProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  ${(props) => {
    return respondTo('md', {
      ['flex']: `1 1 ${props.ratio ? determineWidth(props.ratio) : '100%'}`,
    });
  }}
  justify-content: ${(props) => alignAndJustifyContent(props.justify)};
  align-items: ${(props) => alignAndJustifyContent(props.align)};
  padding: ${space(1)};
`;

export const StyledMiniGridColumn: React.FC<MiniGridColumnProps> = (
  props: MiniGridColumnProps
): JSX.Element => {
  const { children, align = 'start', justify = 'start', ratio } = props;

  return (
    <MiniGridColumn {...props} align={align} justify={justify} ratio={ratio}>
      {children}
    </MiniGridColumn>
  );
};
