import React from 'react';
import styled from 'styled-components';
import { colors } from '../../helpers/colors/colors';
export interface CloseProps extends React.HTMLAttributes<HTMLElement> {
  isOpen: boolean;
}

export const Close = styled.div.attrs({
  className: '',
})<CloseProps>`
  width: 28px;
  height: 28px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  span {
    height: 4px;
    background: ${colors.green};
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    display: block;
    margin: 0;
    border-radius: 1px;
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2) {
      width: 66%;
    }

    &:nth-child(3) {
      width: 33%;
    }
    ${({ isOpen }) =>
      isOpen &&
      `&:nth-child(1),&:nth-child(2),&:nth-child(3) {
        width: 100%;
    }`}
  }
`;

export const StyledClose: React.FC<CloseProps> = (
  props: CloseProps
): JSX.Element => {
  const { isOpen } = props;

  return (
    <Close isOpen={isOpen}>
      <span></span>
      <span></span>
      <span></span>
    </Close>
  );
};

export default StyledClose;
