import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { Alignment } from '../../types';

export interface GroupProps extends React.HTMLAttributes<HTMLElement> {
  align?: Alignment;
}

export const Group = styled.div.attrs({
  className: '',
})<GroupProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.align === 'right' ? 'flex-end' : 'flex-start'};
  flex-wrap: wrap;
  margin-top: -${space(0.5)};
  width: 100%;
  > * {
    flex: 0 0 auto;
    ${(props) =>
      props.align === 'right'
        ? `margin-left: ${space(0.5)};
        margin-top: ${space(0.5)};`
        : `margin-right: ${space(0.5)};
        margin-top: ${space(0.5)};`}
  }
`;

export const StyledGroup: React.FC<GroupProps> = (
  props: GroupProps
): JSX.Element => {
  const { children, align } = props;

  return (
    <Group align={align} {...props}>
      {children}
    </Group>
  );
};

export default StyledGroup;
