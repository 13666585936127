export const scrollIntoView = function (id: string) {
  const pos = document.getElementById(id).offsetTop;
  window.scroll({ top: pos, behavior: 'smooth' });
};

export const isElementInViewport = function (el: HTMLElement) {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
};

export const calculateVisibility = (el: HTMLElement) => {
  if (!el || !window) {
    return 0;
  } else {
    const position = el.getBoundingClientRect();
    if (position.left < window.innerWidth && position.left >= -el.offsetWidth) {
      const perc = position.left / window.innerWidth;
      return perc;
    } else {
      return 0;
    }
  }
};

export function getTouches(evt: TouchEvent) {
  return evt.touches;
}



