import React, { useRef, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { respondTo } from '../../helpers/breakpoints/breakpoints';
import { StyledFront as Front } from './Front';
import { StyledBack as Back } from './Back';
import { HEADLINE_SIZES } from '../../helpers/typography/typography';

// Context
import { ViewportContext } from '../../context/ViewportContext';
export interface SlideProps extends React.HTMLAttributes<HTMLElement> {
  front?: HTMLElement | Element | JSX.Element;
  back?: HTMLElement | Element | JSX.Element;
  index?: number;
  handleMount?: ({ slideIndex, slideWidth }) => {};
  slideGroupCount?: number;
  activeNavigation?: string;
  updateSlideRefs?: (ref: HTMLDivElement) => void;
}

export const Slide = styled.div.attrs({
  className: '',
})<SlideProps>`
  height: 100%;
  width: 100vw;
  flex: 0 0 100vw;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  ${respondTo('md', {
    ['flex-direction']: 'row;',
  })}

  .restricted-content, .content {
    padding: ${space(2)};
    position: absolute;
    width: 100%;
    margin-top: calc(${HEADLINE_SIZES['extra-large']}px - ${space(3)});
    background: transparent;
    margin-bottom: auto;
    > * {
      padding: ${space(0.5)} 0 ${space(1)} 0;
      &:first-child {
        padding: 0;
      }
      &:last-child {
        padding: 0;
      }
    }
  }
  .restricted-content {
    padding: ${space(1)};
    margin-top: calc(${HEADLINE_SIZES.small}px + ${space(6)});
    ${respondTo('lg', {
      ['margin-top']: `calc(${HEADLINE_SIZES.medium}px + ${space(6)})`,
    })}
    ${respondTo('xl', {
      ['margin-top']: `calc(${HEADLINE_SIZES['extra-large']}px + ${space(6)})`,
    })}
    ${respondTo('sm', {
      ['max-width']: '600px;',
    })}0
    ${respondTo('md', {
      ['max-width']: '650px;',
    })}
    ${respondTo('lg', {
      ['max-width']: '700px;',
    })}
  }
`;

export const StyledSlide: React.FC<SlideProps> = (
  props: SlideProps
): JSX.Element => {
  const {
    front,
    back,
    index,
    handleMount,
    slideGroupCount,
    activeNavigation,
    updateSlideRefs,
    children,
  } = props;
  const viewportContext = useContext(ViewportContext);

  const [reverseOrder, setReverseOrder] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref) {
      handleMount({
        slideIndex: index,
        slideWidth: ref.current.offsetWidth,
      });
    }
  }, [viewportContext]);

  useEffect(() => {
    if (index > slideGroupCount / 2) {
      setReverseOrder(true);
    } else {
      setReverseOrder(false);
    }
  }, [slideGroupCount]);

  useEffect(() => {
    if (ref.current) {
      updateSlideRefs(ref.current);
    }
  }, [ref]);

  return (
    <Slide
      index={index}
      innerRef={ref}
      handleMount={handleMount}
      activeNavigation={activeNavigation}
      slideGroupCount={slideGroupCount}
      updateSlideRefs={updateSlideRefs}
      ref={ref}
      className={'slide-' + index}
    >
      {front && (
        <Front position={reverseOrder ? 'right' : 'left'}>{front}</Front>
      )}
      {back && <Back position={reverseOrder ? 'left' : 'right'}>{back}</Back>}
      {children && children}
    </Slide>
  );
};

export default StyledSlide;
