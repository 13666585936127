import * as React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../helpers/breakpoints/breakpoints';

export interface BackdropProps extends React.HTMLAttributes<HTMLElement> {
  image: string;
  height?: number | string;
  width?: number | string;
  insetLeft?: string;
  insetRight?: string;
  sizeMode?: 'cover' | 'contain';
}

export const Backdrop = styled.div.attrs({
  className: '',
})<BackdropProps>`
  background-size: ${(props) => props.sizeMode};
  background-position: bottom center;
  background-repeat: no-repeat;
  height: ${(props) => `${props.height}`};
  min-height: 480px;
  width: ${(props) => `${props.width}`};
  max-width: 100%;
  ${respondTo('lg', {
    ['max-width']: '600px',
  })}
  border-radius: 2px;
  margin-left: ${(props) => props.insetLeft};
  margin-right: ${(props) => props.insetRight};
`;

export const StyledBackdrop: React.FC<BackdropProps> = (
  props: BackdropProps
): JSX.Element => {
  const {
    image,
    height = '128px',
    width = '128px',
    insetLeft = '0px',
    insetRight = '0px',
    sizeMode = 'cover',
  } = props;
  const backdropImage = require('../../../static/img/' + image);

  return (
    <Backdrop
      insetLeft={insetLeft}
      insetRight={insetRight}
      height={height}
      width={width}
      sizeMode={sizeMode}
      style={{ backgroundImage: `url(${backdropImage.default})` }}
    />
  );
};

export default StyledBackdrop;
