import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledHeadline as Headline } from '../headline/Headline';
import { StyledButton as Button } from '../button/Button';
import { StyledSymbol as Symbol } from '../symbol/Symbol';

import { space } from '../../helpers/space';
import { SlideGroup as SlideGroupType } from '../../types';

export interface SlideGroupProps extends React.HTMLAttributes<HTMLElement> {
  headline?: string;
  name?: string;
  activeSlide?: number;
  activeNavigation?: string;
  handleMount?: (SlideGroupType) => void;
  changeNavigation?: (navigationTarget: string) => void;
  updateSlideRefs?: (ref: HTMLDivElement) => void;
  updateSlideGroupRefs?: (ref: HTMLDivElement) => void;
  previous?: { title: string; onClick: (target: string) => void };
}

export const SlideGroup = styled.div.attrs({
  className: '',
})<SlideGroupProps>`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  .headliner-scroller {
    position: sticky;
    top: 0;
    left: 0;
    width: 50px;
  }

  .floating-headline {
    position: sticky;
    left: 0;
    text-align: left;
    mix-blend-mode: difference;
    text-shadow: 0px 0px 2px white;
    z-index: 2;
    padding-left: ${space(6)};
  }

  .slides {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
`;

export const StyledSlideGroup: React.FC<SlideGroupProps> = (
  props: SlideGroupProps
): JSX.Element => {
  const {
    children,
    headline,
    name,
    activeSlide,
    activeNavigation,
    handleMount,
    changeNavigation,
    updateSlideRefs,
    updateSlideGroupRefs,
    previous,
  } = props;

  const [slideIndexes, setSlideIndexes] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [slideWidth, setSlideWidth] = useState(0);
  const [slideGroupLength, setSlideGroupLength] = useState(0);

  const groupRef = useRef(null);

  useEffect(() => {
    if (groupRef.current) {
      updateSlideGroupRefs(groupRef.current);
    }
  }, [groupRef]);

  useEffect(() => {
    if (slideIndexes.includes(activeSlide)) {
      setIsActive(true);
      changeNavigation(name);
    } else {
      setIsActive(false);
    }
  }, [activeSlide]);

  const handleSlideMount = ({ slideIndex, slideWidth }) => {
    if (groupRef.current) {
      const slides = groupRef.current.querySelector('.slides').children;
      setSlideWidth(slideWidth);
      setSlideGroupLength(slides.length);
      setSlideIndexes((oldIndexes) => [...oldIndexes, slideIndex]);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        activeSlide: activeSlide,
        activeNavigation: activeNavigation,
        handleMount: handleSlideMount,
        changeNavigation: changeNavigation,
        slideGroupCount: slideIndexes.length,
        updateSlideRefs: updateSlideRefs,
      });
    }
    return child;
  });

  return (
    <SlideGroup
      ref={groupRef}
      handleMount={handleMount}
      style={{ width: `${slideGroupLength * slideWidth}px` }}
      {...props}
    >
      {headline && (
        <div className="floating-headline">
          <div style={{ height: `${space(3)}` }}>
            {previous && (
              <Button
                onClick={previous.onClick}
                hasBorder={false}
                lg={'large'}
                xl={'extra-large'}
              >
                <Symbol symbolStyle="fal" symbolName={'arrow-left'} size="sm" />
                {previous.title}
              </Button>
            )}
          </div>
          <Headline
            as="h2"
            align="left"
            isNarrow={true}
            fontWeight={800}
            uppercase={true}
            md={'medium'}
            lg={'large'}
            xl={'extra-large'}
          >
            {headline}
          </Headline>
        </div>
      )}
      <div
        className="slides"
        style={{ width: `${slideGroupLength * slideWidth}px` }}
      >
        {childrenWithProps}
      </div>
    </SlideGroup>
  );
};

export default StyledSlideGroup;
