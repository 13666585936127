import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { space } from '../helpers/space';
import { BreakpointKeys } from '../helpers/breakpoints/breakpoints';
import { StyledSection as Section } from '../components/section/Section';
import { StyledSymbol as Symbol } from '../components/symbol/Symbol';
import { StyledAvatar as Avatar } from '../components/avatar/Avatar';
import { StyledCard as Card } from '../components/card/Card';
import {
  StyledMiniGrid as Grid,
  StyledMiniGridRow as Row,
  StyledMiniGridColumn as Column,
} from '../components/mini-grid/MiniGrid';
import { StyledParagraph as Paragraph } from '../components/paragraph/Paragraph';
import { StyledHeadline as Headline } from '../components/headline/Headline';
import { StyledBackdrop as Backdrop } from '../components/backdrop/Backdrop';
import { StyledGroup as Group } from '../components/group/Group';
import { StyledPill as Pill } from '../components/pill/Pill';
import { StyledLogo as Logo } from '../components/logo/Logo';
import { StyledButton as Button } from '../components/button/Button';

// Data
import content from '../data/leistungen.json';
import team from '../data/team.json';
import projects from '../data/projects.json';
import { scrollIntoView } from '../helpers/utilities';

const LOGO_SIZE = {
  none: 64,
  sm: 128,
  md: 164,
  lg: 164,
  xl: 128,
  '2xl': 164,
};
export interface MobileViewProps extends React.HTMLAttributes<HTMLElement> {
  innerRef?: HTMLElement | null;
  activeNavigation: string;
  changeNavigation: (navigationTarget: string) => void;
  currentBreakpoint: BreakpointKeys;
}

export const MobileView = styled.div.attrs({
  className: '',
})<MobileViewProps>`
  display: flex;
  flex-direction: column;
`;

export const StyledMobileView: React.FC<MobileViewProps> = (
  props: MobileViewProps
): JSX.Element => {
  const { children, changeNavigation, activeNavigation, currentBreakpoint } =
    props;

  const el = useRef(null);

  useEffect(() => {
    if (activeNavigation.length) {
      scrollIntoView(activeNavigation);
    }
  }, [activeNavigation]);

  useEffect(() => {
    const container = el.current;

    if (container) {
      window.addEventListener('scroll', function () {
        // runAnimations();
      });
    }

    return function cleanup() {
      window.removeEventListener('scroll', runAnimations);
    };
  }, [el]);

  const runAnimations = () => {
    const scrollTop = window.pageYOffset;
    console.log(scrollTop);
    const container = el.current;
    if (container) {
      const slides = container.querySelectorAll('.slide');
      if (slides.length > 0) {
        slides.forEach((slide, index) => {
          const image = slide.querySelector('.image');
          const text = slide.querySelector('.text');
          image.style.transform = `translateX(calc(${index * -20} + ${
            scrollTop / 100
          }px))`;

          text.style.transform = `translateX(calc(${index * -40} + ${
            scrollTop / 100
          }px))`;
        });
      }
      console.log(slides);
    }
  };

  return (
    <MobileView
      ref={el}
      activeNavigation={activeNavigation}
      changeNavigation={changeNavigation}
      {...props}
    >
      <Section id="home">
        <Grid>
          <Row>
            <Column justify={'center'} align={'start'}>
              <Logo
                rotation={0}
                height={LOGO_SIZE[currentBreakpoint]}
                hasWordmark={true}
                title={'Home'}
                to={'/'}
              />
            </Column>
          </Row>
          <Row>
            <Column justify={'center'} align={'end'}>
              <Paragraph align={'right'} fontWeight={600}>
                Wir gestalten, entwickeln & optimieren deinen Webauftritt.
                <br />
                Professionell. Zielgerichtet. Individuell.
              </Paragraph>
              <Paragraph align={'right'}>
                <Button
                  hasEmphasis={false}
                  hasBorder={true}
                  isPrimary={true}
                  onClick={() => null}
                >
                  <span>
                    {'Kontaktiere uns unter'}{' '}
                    <strong>fragste@klickste.berlin</strong>
                  </span>
                </Button>
              </Paragraph>
            </Column>
          </Row>
          <Row>
            <Column justify={'end'} align={'end'}>
              <Button
                onClick={() => changeNavigation('leistungen')}
                hasBorder={false}
                none={'medium'}
                md={'large'}
              >
                <Symbol
                  symbolStyle="fal"
                  symbolName={'arrow-right'}
                  size="sm"
                />
                Leistungen
              </Button>
            </Column>
          </Row>
        </Grid>
      </Section>
      <Section id="leistungen">
        <Grid>
          <Row>
            <Column>
              <Headline
                as="h2"
                fontWeight="bold"
                uppercase={true}
                none={'small'}
                md={'medium'}
              >
                Leistungen
              </Headline>
            </Column>
          </Row>
          {content.map((c, index) => {
            return (
              <Row
                key={index}
                style={{ padding: `${space(1)} 0px` }}
                className="slide"
              >
                <Column className="image" ratio={'1/3'}>
                  <Backdrop
                    image={c.image}
                    width={'100%'}
                    height={'33vh'}
                    sizeMode={'cover'}
                  />
                </Column>
                <Column className="text" ratio={'2/3'}>
                  <Headline
                    as="h3"
                    none={'tiny'}
                    lg="extra-small"
                    fontWeight="bold"
                    align="right"
                    hasSpacing={true}
                  >
                    {c.headline}
                  </Headline>
                  <Paragraph align="right">{c.content}</Paragraph>
                  <Group align="right">
                    {c.keywords.map((word, index) => (
                      <Pill key={index}>{word}</Pill>
                    ))}
                  </Group>
                </Column>
              </Row>
            );
          })}
        </Grid>
      </Section>
      <Section id="team">
        <Grid>
          <Row>
            <Column>
              <Headline
                as="h2"
                none="small"
                sm="medium"
                fontWeight="bold"
                uppercase={true}
              >
                Team
              </Headline>
            </Column>
          </Row>
          <Row>
            <Column>
              <Group align="right">
                {team.map((member, index) => (
                  <Avatar
                    key={index}
                    image={member.image}
                    name={member.name}
                    title={member.position}
                    height={220}
                    width={166}
                  />
                ))}
              </Group>
            </Column>
          </Row>
        </Grid>
      </Section>
      {/* <Section id="referenzen">
        <Grid>
          <Row>
            <Column>
              <Headline
                as="h2"
                none="small"
                sm="medium"
                fontWeight="bold"
                uppercase={true}
              >
                Referenzen
              </Headline>
            </Column>
          </Row>
          <Row>
            <Column>
              <Group align="right">
                {projects.map((member, index) => (
                  <Card
                    key={index}
                    width={200}
                    height={200}
                    image={member.image}
                    name={member.name}
                    title={member.position}
                    sizeMode={'contain'}
                  />
                ))}
              </Group>
            </Column>
          </Row>
        </Grid>
      </Section> */}
    </MobileView>
  );
};

export default StyledMobileView;
