import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';
import { colors } from '../../helpers/colors/colors';

export interface PillProps extends React.HTMLAttributes<HTMLElement> {}

export const Pill = styled.label.attrs({
  className: 'pill',
})<PillProps>`
  display: flex;
  flex-direction: row;
  padding: ${space(0.5)};
  min-width: 60px;
  border-radius: 2px;
  background: ${colors.green};
  color: ${colors.white};
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  * > {
    &:first-child {
      margin-right: ${space(0.25)};
    }
  }
`;

export const StyledPill: React.FC<PillProps> = (
  props: PillProps
): JSX.Element => {
  const { children } = props;

  return <Pill>{children}</Pill>;
};

export default StyledPill;
